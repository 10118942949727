import {Link} from "gatsby";
import React, {FC} from "react";
import Code from "../../components/code";
import Layout from "../../components/layout/layout-sidebar";
import Seo from "../../components/seo";
import {sidebarItemsGettingStarted} from "../../data/sidebar-items-getting-started";
import {apiDocsSlug, gettingStartedSlug} from "../../helpers/slug-helper";

const DataStructuresPage: FC = () => (
    <Layout sidebarItems={sidebarItemsGettingStarted}>
        <Seo
            title="Getting started with the data structure"
            description="Explanation of the grid data structure used in gridl."
        />
        <section className="p-8">
            <h1>{`The grid data structure`}</h1>
            <p>
                {`In gridl, a grid is an immutable grid-based data structure that has the following properties, which should be self-explanatory.`}
            </p>
            <Code code={codeExample1} language="javascript" />

            <h2>{`The data array`}</h2>
            <p>
                {`The `}
                <code>array2D</code>
                {` data array is a two-dimensional array that contains the actual cells. The coordinate system starts at the upper left corner at `}
                <code className="inline-code">{`{x: 0, y: 0}`}</code>
                {` and ends at the bottom right corner, which would be `}
                <code className="inline-code">{`{x: 3, y: 2}`}</code>
                {` in the example above. It can contain any values like primitive strings, numbers or booleans or complex objects. In most cases you don't need to access it directly. Instead you can use one of gridl's `}
                <Link to={gettingStartedSlug("grid-selectors")}>selector functions</Link>
                {`.`}
            </p>

            <h2>{`Creating a new grid from scratch`}</h2>
            <p>
                {`gridl provides some factory functions to create grid instances. To create a new grid from scratch use `}
                <Link to={apiDocsSlug("createGrid")}>createGrid</Link>
                {`:`}
            </p>
            <Code code={codeExample2} language="javascript" />

            <h2>{`Creating a new grid from existing data`}</h2>
            <p>
                {`To create a grid instance from an existing data array, use `}
                <Link to={apiDocsSlug("createGridFromArray2D")}>createGridFromArray2D</Link>
                {`:`}
            </p>
            <Code code={codeExample3} language="javascript" />
        </section>
    </Layout>
);

export default DataStructuresPage;

const codeExample1 = `{
    x: 2,
    y: 1,                       
    cellCount: 12,
    columnCount: 4,
    rowCount: 3,
    array2D: [
        [  0,   1,   2,   3],
        [  4,   5,   6,   7],
        ["x", "x", "x", "x"],
    ],
}`;

const codeExample2 = `
import {createGrid} from "gridl/core";

const grid = createGrid({
    columnCount: 4,
    rowCount: 3,
    createCell: (pos, idx) => pos.y < 2 ? idx : "x",
});
// {
//     x: 0,
//     y: 0,
//     cellCount: 12,
//     columnCount: 4,
//     rowCount: 3,
//     array2D: [
//         [  0,   1,   2,   3],
//         [  4,   5,   6,   7],
//         ["x", "x", "x", "x"],
//     ],
// }
`;

const codeExample3 = `import {createGridFromArray2D} from "gridl/core";

const grid = createGridFromArray2D([
    [0,  1,  2,  3],
    [4,  5,  6,  7],
    [8,  9, 10, 11],
]);`;
